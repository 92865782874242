import baseApi from "./baseApi";
import { CheckSumPdfRegion, CheckSumPdfRegionArgs } from "types/models/CheckSumPdfRegion";
import { PdfToExcelConversion, PdfToExcelConversionArgs } from "types/models/PdfToExcelConversion";
import { CorrectTables, CorrectTablesArgs } from "types/models/CorrectTables";
import { ExcelToTableConversionArgs } from "types/models/ExcelToTableConversion";

export type ExportExcelTableArgs = {
    resultDocumentId: number
}

const checkSumApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        autodetectRegions: build.mutation<CheckSumPdfRegion[], CheckSumPdfRegionArgs>({
            query: (args) => {
                const url = "quality-check/check-sum/autodetect-regions";
                return {
                    url,
                    config: {
                        method: "POST",
                        data: args,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }
                    }
                }
            }
        }),
        pdfToExcelConversion: build.mutation<PdfToExcelConversion, PdfToExcelConversionArgs>({
            query: (args) => {
                const url = "quality-check/check-sum/pdf";
                return {
                    url,
                    config: {
                        method: "POST",
                        data: {
                            file: args.file,
                            tableRegions: new Blob([JSON.stringify(args.tableRegions)], {type: "application/json"})
                        },
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }
                    }
                }
            }
        }),
        correctTables: build.mutation<CorrectTables[], CorrectTablesArgs>({
            query: (args) => {
                const url = "quality-check/check-sum/correct-tables";
                return {
                    url,
                    config: {
                        method: "POST",
                        data: args,
                    }
                }
            }
        }),
        excelToTableConversion: build.mutation<PdfToExcelConversion, ExcelToTableConversionArgs>({
            query: (args) => {
                const url = "check-sum/read-excel";
                return {
                    url,
                    config: {
                        method: "POST",
                        data: args,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }
                    }
                }
            }
        }),
        exportExcelTable: build.query<Blob, ExportExcelTableArgs>({
            query: (args) => {
                return {
                    url: `quality-check/check-sum/excel-table/${args.resultDocumentId}/export`,
                    config: {
                        method: "GET",
                        responseType: "blob",
                    }
                }
            }
        }),
    })
});

export default checkSumApi;