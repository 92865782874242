import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
import qualityCheckApi from 'store/apis/qualityCheckApi';
import { useEffect, useState } from 'react';
import { Button, ButtonBase, IconButton, Stack, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout } from 'features/authSlice';
import { RootState } from 'store/store';

type AppbarProps = {
    toggleSideBar: () => void;
}

const Appbar = (props: AppbarProps) => {
    const { data: user, isSuccess, isError, isLoading, refetch } = qualityCheckApi.useQualityCheckJobUserProfileQuery();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
    const [usage, setUsage] = useState(0);

    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    useEffect(() => {
        if (isSuccess) {
            dispatch(login(user))
            setUsage(user?.usage.callFigure + user?.usage.glossaryCheck + user?.usage.sumCheck + user?.usage.glossaryExtract)
        }
        if (isError) {
            dispatch(logout())
        }
    }, [isLoading])

    const handleLogout = () => {
        setAnchorElUser(null);
        refetch();
        axios.post("/logout", null)
            .then(response => {
                if (response.status === 200) {
                    dispatch(logout());
                }
            })
            .catch((error) => { })
    };

    const handleMenuItemOnClick = (destination: String) => {
        setAnchorElUser(null);
        navigate(`/${destination}`)
    }

    const renderMenu = (
        <Menu
            sx={{ mt: '45px', paddingTop: "0px", paddingBottom: "0px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={() => setAnchorElUser(null)}
        >
            {/* <MenuItem
                onClick={() => handleMenuItemOnClick("profile")}
                divider
            >
                <Typography textAlign="center">Profile</Typography>
            </MenuItem> */}
            <MenuItem
                onClick={() => handleMenuItemOnClick("my-document")}
            >
                <Typography textAlign="center">Document</Typography>
            </MenuItem>

        </Menu>
    )

    return (
        <AppBar position='fixed' style={{ backgroundColor: 'white', borderBottom: "1px solid RGB(0,0,0,0.12)", top: "0px" }} elevation={0}>
            <Toolbar
                sx={{
                    height: 58,
                    minHeight: 58,
                }}
            >
                <IconButton onClick={props.toggleSideBar} edge="start" sx={{ mr: "5px" }}>
                    <MenuIcon sx={{ color: "black" }} />
                </IconButton>
                <Box sx={{ display: "flex" }}>
                    <NavLink className="topnav__logo-navlink" to="/">
                        <Typography
                            variant="h6"
                            color={"black"}
                            letterSpacing={"0.3rem"}
                        >
                            {"A"}
                            <span style={{ color: "darkgreen" }}>
                                {"DD"}
                            </span>
                            {"CURACY"}
                            {/* {isLoggedIn && user?.subscriptionStatus === "ACTIVE" && <span>{"+"}</span>} */}
                        </Typography>
                    </NavLink>
                </Box>
                <Box flexGrow={1} />
                {isLoggedIn &&
                    <Box sx={{ display: 'flex', alignItems: "center" }}>
                        <Tooltip
                            title={(
                                <>
                                    callfigure: {user?.usage.callFigure} <br />
                                    glossary check: {user?.usage.glossaryCheck} <br />
                                    sum check: {user?.usage.sumCheck} <br />
                                    glossary extract: {user?.usage.glossaryExtract}
                                </>
                            )}
                        >
                            <Box>
                                <Typography color={"black"}>
                                    {"Usage: " + usage}
                                </Typography>
                            </Box>
                        </Tooltip>
                        <Button
                            onClick={() => handleMenuItemOnClick("my-document")}
                        >
                            {"My Document"}
                        </Button>

                        {/* <ButtonBase onClick={(event) => { setAnchorElUser(event.currentTarget) }}>
                            <MoreVertIcon sx={{ color: "black" }} />
                        </ButtonBase>
                        {renderMenu} */}
                    </Box>
                }
                {/* {isLoggedIn &&
                    <Box sx={{ display: 'flex' }}>
                        <Box>
                            <Typography color={"black"}>
                                {"Credit: " + user?.creditBalance}
                            </Typography>
                        </Box>
                        <ButtonBase onClick={(event) => { setAnchorElUser(event.currentTarget) }}>
                            <MoreVertIcon sx={{ color: "black" }} />
                        </ButtonBase>
                        {renderMenu}
                        <ButtonBase onClick={handleLogout}>
                            <LogoutIcon
                                sx={{ color: "black" }}
                            />
                        </ButtonBase>
                    </Box>
                } */}
                {/* {!isLoggedIn &&
                    <Box sx={{ display: 'flex' }}>
                        <Button onClick={() => navigate("/login")}>
                            Sign In
                        </Button>
                    </Box>
                } */}
            </Toolbar>
        </AppBar>
    );
}

export default Appbar