import { Client, closeEventCallbackType, frameCallbackType } from "@stomp/stompjs";

const brokerURL = process.env.REACT_APP_WS_URL;

const connectionCloseCallback: closeEventCallbackType[] = [];

export const registerConnectionCloseCallback = (callback: closeEventCallbackType) => {
        connectionCloseCallback.push(callback);
        return () => {
                const index = connectionCloseCallback.indexOf(callback);
                if (index !== -1) {
                        connectionCloseCallback.splice(index, 1);
                }
        }
}

export const stompClient = new Client({
        brokerURL: brokerURL,
        // heartbeatIncoming: 10000,
        // heartbeatOutgoing: 10000,
        reconnectDelay: 300,
        // onDisconnect: (frame) => {
        //         console.log('Connection to the server has been disconnected.');
        // },
        // debug: (str) => {
        //         console.log(new Date(), 'Debug:', str);
        // },
        onWebSocketClose: (evt) => {
                // console.log('Connection closed:', {
                //         code: evt.code,
                //         reason: evt.reason,
                //         wasClean: evt.wasClean
                // });
                connectionCloseCallback.forEach(callback => callback(evt));
        }
})