import { Backdrop, Box, Button, CircularProgress, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { RenderPageProps, Viewer, Worker } from "@react-pdf-viewer/core";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { BoxSelection, BoxSelectionObj, BoxSelectionWrapper, SelectorDeleteContext } from "types/BoxSelector";
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';

// Import styles
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import customZoomPlugin from "./CustomZoomPlugin/customZoomPlugin";
import checkSumApi from "store/apis/checkSumApi";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import qualityCheckApi from "store/apis/qualityCheckApi";

type PDFViwerProps = {
    fileType: FileTypes;
    fileBytes: Uint8Array | undefined;
    files: File[];
    setCheckSumResultTables: (tables: any) => void;
    onClickBack: () => void;
}

enum FileTypes {
    None,
    Pdf,
    Excel,
    ExtractedTables,
    ExtractedTablesFromExcel,
    Results
};

const PDFViewr = (props: PDFViwerProps) => {

    const { refetch: updateCredit } = qualityCheckApi.useQualityCheckJobUserProfileQuery();

    const [autodetectRegions, { data: autodetectedRegions, isLoading: isAutodetectRegions }] = checkSumApi.useAutodetectRegionsMutation();
    const [generateTablesFromPdf, { data: generatedTables, isLoading: isGeneratingTablesFromPdf }] = checkSumApi.usePdfToExcelConversionMutation();

    const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
    const pdfContainerRef = useRef<HTMLDivElement | null>(null);
    const customZoomPluginInstance = customZoomPlugin();
    const { zoomTo } = customZoomPluginInstance;
    const [scale, setScale] = useState(1.0);

    const [currentBoxId, setCurrentBoxId] = useState(0);
    const [currentBox, setCurrentBox] = useState(undefined as BoxSelection | undefined);
    const [currentBoxes, setCurrentBoxes] = useState([] as BoxSelectionObj[]);

    const [position, setPosition] = useState({
        x: 0,
        y: 0
    });

    const onConvertToTableHandler = () => {
        setIsOpenConfirmDialog(false);
        if (currentBoxes.length === 0 && props.files.length >= 1 && props.fileType === FileTypes.Pdf) {
            return;
        }
        generateTablesFromPdf({
            file: props.files[0],
            tableRegions: currentBoxes.map((b) => {
                return ({
                    topPercent: b.percentTop,
                    bottomPercent: b.percentBottom,
                    leftPercent: b.percentLeft,
                    rightPercent: b.percentRight,
                    pageNumber: b.targetId
                })
            })
        }).then(() => updateCredit());
    };

    useEffect(() => {
        if (generatedTables !== undefined) {
            props.setCheckSumResultTables(generatedTables);
        }
    }, [generatedTables]);

    const handleWheel = (event: WheelEvent) => {
        if (event.ctrlKey) {
            event.preventDefault();
            if (event.deltaY < 0) {
                setScale((prevScale) => {
                    const newScale = Math.min(prevScale + 0.05, 3.0);
                    zoomTo(newScale);
                    return newScale;
                });
            } else {
                setScale((prevScale) => {
                    const newScale = Math.max(prevScale - 0.05, 0.5);
                    zoomTo(newScale);
                    return newScale;
                });
            }
        }
    };

    useEffect(() => {
        const pdfContainer = pdfContainerRef.current;
        if (pdfContainer) {
            pdfContainer.addEventListener('wheel', handleWheel);
        }
        return () => {
            if (pdfContainer) {
                pdfContainer.removeEventListener('wheel', handleWheel);
            }
        };
    }, []);


    const onMouseDownHandler = (event: MouseEvent, pageNumber: number) => {
        if (document.getElementById("selector-image-page-" + (pageNumber.toString())) === null ||
            document.getElementById("selector-pages-container-offset-parent") === null) {
            return;
        }
        const targetObjRef = document.getElementById("selector-image-page-" + (pageNumber.toString())) as HTMLElement;
        const relativeRef = document.getElementById("selector-pages-container-offset-parent") as HTMLElement;
        let offsetXSum = targetObjRef.getBoundingClientRect().x - relativeRef.getBoundingClientRect().x;
        let offsetYSum = targetObjRef.getBoundingClientRect().y - relativeRef.getBoundingClientRect().y;
        let firstChild = (relativeRef.firstChild as HTMLElement);
        while (firstChild) {
            if (firstChild.scrollTop !== 0 || firstChild.scrollHeight !== firstChild.clientHeight) {
                offsetYSum += firstChild.scrollTop;
                break;
            }
            if (firstChild.firstChild === null) {
                break;
            }
            firstChild = firstChild.firstChild as HTMLElement;
        }
        const newBox = new BoxSelection(event.offsetX, event.offsetY, offsetXSum, offsetYSum, pageNumber,
            targetObjRef.offsetWidth, targetObjRef.offsetHeight, currentBoxId);
        setPosition({
            x: event.offsetX,
            y: event.offsetY
        });
        setCurrentBoxId(currentBoxId + 1);
        setCurrentBox(newBox);
    };

    const onMouseMoveHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, pageNumber: number) => {
        if (currentBox !== undefined) {
            const targetObjRef = document.getElementById("selector-image-page-" + (pageNumber.toString())) as HTMLElement;
            const offsetXC = (event.clientX - targetObjRef.getBoundingClientRect().x);
            const offsetYC = (event.clientY - targetObjRef.getBoundingClientRect().y);
            setPosition({
                x: offsetXC,
                y: offsetYC
            });
        }
    }

    const onMouseUpHandler = (event: MouseEvent) => {
        if (currentBox !== undefined && !currentBox.fixed) {
            setCurrentBox((cbAtomicity) => {
                if (cbAtomicity === undefined) {
                    return undefined;
                }
                if (document.getElementById("selector-highlight-box-" + cbAtomicity.id) !== null) {
                    return undefined;
                }
                const targetObjRef = document.getElementById("selector-image-page-" + (cbAtomicity.targetId.toString())) as HTMLElement;
                const offsetXC = (event.clientX - targetObjRef.getBoundingClientRect().x);
                const offsetYC = (event.clientY - targetObjRef.getBoundingClientRect().y);
                const currentBoxLcl = cbAtomicity as BoxSelection;
                currentBoxLcl.update(offsetXC, offsetYC);
                const offsetL = Math.min(offsetXC, currentBoxLcl.offsetX);
                const offsetR = Math.max(offsetXC, currentBoxLcl.offsetX);
                const offsetT = Math.min(offsetYC, currentBoxLcl.offsetY);
                const offsetB = Math.max(offsetYC, currentBoxLcl.offsetY);
                if ((offsetR - offsetL < 1) && (offsetT - offsetB < 1)) {
                    return undefined;
                }
                setCurrentBoxes((currentBoxesAtomicity) => {
                    const currentBoxesLcl = [] as BoxSelectionObj[];
                    for (let i = 0; i < currentBoxesAtomicity.length; ++i) {
                        const bsO: BoxSelectionObj = { ...currentBoxesAtomicity[i] };
                        currentBoxesLcl.push(bsO);
                    }
                    currentBoxesLcl.push({
                        bc: currentBoxLcl.getCoordsFromEnd(),
                        id: currentBoxLcl.id,
                        targetId: currentBoxLcl.targetId,
                        percentTop: offsetT / currentBoxLcl.underlyingHeight * 100.0,
                        percentBottom: offsetB / currentBoxLcl.underlyingHeight * 100.0,
                        percentLeft: offsetL / currentBoxLcl.underlyingWidth * 100.0,
                        percentRight: offsetR / currentBoxLcl.underlyingWidth * 100.0
                    });
                    return currentBoxesLcl;
                });
                return undefined;
            });
        }
    }

    useEffect(() => {
        window.addEventListener("mouseup", (e) => { onMouseUpHandler(e) });
    });

    const PDFPageSelectorWrapper: React.FC<{ pageIndex: number, scale: number }> = ({ pageIndex, scale }) => {
        return (
            <div
                style={{
                    alignItems: 'left',
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'left',
                    left: 0,
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    fontSize: `${12 * scale}px`,
                    fontWeight: 'bold',
                    userSelect: 'none',
                    zIndex: '100',
                }}
                unselectable="on"
                draggable="false"
                id={"selector-image-page-" + ((pageIndex + 1).toString())}
                onMouseDown={(e) => { onMouseDownHandler(e.nativeEvent, pageIndex + 1) }}
                onMouseMove={(e) => onMouseMoveHandler(e, pageIndex + 1)}
            >
                <Typography component="span" sx={{ paddingLeft: "5px" }}>Page {pageIndex + 1}</Typography>
            </div>
        );
    };

    const boxDeleteHandler = (event: MouseEvent) => {
        const target_id = (event?.target as HTMLElement).id;
        //assert(target_id.substring(target_id.length - 7) == "-button");
        const target_box_id = target_id.substring(0, target_id.length - 7);
        //const boxdiv = document.getElementById(target_box_id);
        const boxdivid_number = parseInt(target_box_id.split("-").pop() as string)
        //boxdiv?.remove();
        setCurrentBoxes((currentBoxesAtomicity) => {
            const currentBoxesLcl = [] as BoxSelectionObj[];
            for (let i = 0; i < currentBoxesAtomicity.length; ++i) {
                if (currentBoxesAtomicity[i].id !== boxdivid_number) {
                    currentBoxesLcl.push({ ...currentBoxesAtomicity[i] });
                }
            }
            return currentBoxesLcl;
        });
    }

    const renderPage = (props: RenderPageProps) => {
        // based on https://react-pdf-viewer.dev/examples/disable-text-selection/, https://react-pdf-viewer.dev/examples/add-a-watermark/
        return (
            <>
                {props.canvasLayer.children}
                <PDFPageSelectorWrapper pageIndex={props.pageIndex} scale={props.scale} />
                <div style={{ userSelect: 'none' }}>
                    {props.textLayer.children}
                </div>
                {props.annotationLayer.children}
            </>
        );
    };

    useEffect(() => {
        if (autodetectedRegions !== undefined) {
            setCurrentBoxes((currentBoxesAtomicity) => {
                const startBoxId = currentBoxId;
                const endBoxId = startBoxId + autodetectedRegions.length;
                setCurrentBoxId(endBoxId);
                const currentBoxesCpy = [] as BoxSelectionObj[];
                for (let i = 0; i < currentBoxesAtomicity.length; ++i) {
                    const bsO: BoxSelectionObj = { ...currentBoxesAtomicity[i] };
                    currentBoxesCpy.push(bsO);
                }
                for (let i = 0; i < autodetectedRegions.length; ++i) {
                    const autodetectedRegionLcl = autodetectedRegions[i];
                    const pageNumber = autodetectedRegionLcl.pageNumber;
                    const cbId: number = startBoxId + i;
                    const selfId = "selector-highlight-box-" + cbId;
                    if (document.getElementById(selfId) !== null) {
                        continue;
                    }
                    const targetObjId = "selector-image-page-" + pageNumber;
                    if (document.getElementById(targetObjId) === null) {
                        continue;
                    }
                    const targetObjRef = (document.getElementById(targetObjId) as HTMLElement);
                    const underlyingWidth = targetObjRef.offsetWidth;
                    const underlyingHeight = targetObjRef.offsetHeight;
                    const coordsTop = autodetectedRegionLcl.topPercent * underlyingHeight / 100;
                    const coordsLeft = autodetectedRegionLcl.leftPercent * underlyingWidth / 100;
                    const coordsBottom = autodetectedRegionLcl.bottomPercent * underlyingHeight / 100;
                    const coordsRight = autodetectedRegionLcl.rightPercent * underlyingWidth / 100;
                    const relativeRef = document.getElementById("selector-pages-container-offset-parent") as HTMLElement;
                    let offsetXSum = targetObjRef.getBoundingClientRect().x - relativeRef.getBoundingClientRect().x;
                    let offsetYSum = targetObjRef.getBoundingClientRect().y - relativeRef.getBoundingClientRect().y;
                    let firstChild = (relativeRef.firstChild as HTMLElement);
                    while (firstChild) {
                        if (firstChild.scrollTop !== 0 || firstChild.scrollHeight !== firstChild.clientHeight) {
                            offsetYSum += firstChild.scrollTop;
                            break;
                        }
                        if (firstChild.firstChild === null) {
                            break;
                        }
                        firstChild = firstChild.firstChild as HTMLElement;
                    }
                    const drawX = coordsLeft + offsetXSum;
                    const drawY = coordsTop + offsetYSum;
                    const widthX = coordsRight - coordsLeft;
                    const heightY = coordsBottom - coordsTop;
                    const retBoxObj: BoxSelectionObj = {
                        bc: {
                            top: drawY + "px",
                            left: drawX + "px",
                            width: widthX + "px",
                            height: heightY + "px"
                        },
                        percentBottom: autodetectedRegionLcl.bottomPercent,
                        percentTop: autodetectedRegionLcl.topPercent,
                        percentLeft: autodetectedRegionLcl.leftPercent,
                        percentRight: autodetectedRegionLcl.rightPercent,
                        id: cbId,
                        targetId: pageNumber
                    }
                    currentBoxesCpy.push(retBoxObj);
                }
                return currentBoxesCpy;
            });
        }
    }, [autodetectedRegions]);

    return (
        <Box ref={pdfContainerRef}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme: { zIndex: { drawer: number; }; }) => theme.zIndex.drawer + 1 }}
                open={isAutodetectRegions || isGeneratingTablesFromPdf}
            >
                <CircularProgress/>
            </Backdrop>
            <ConfirmDialog
                open={isOpenConfirmDialog}
                title="Confirm process tables?"
                content="Total numbers from tables will calculate usage. Are you sure you want to proceed?"
                onClose={() => setIsOpenConfirmDialog(false)}
                onConfirm={onConvertToTableHandler}
            />
            <SelectorDeleteContext.Provider value={boxDeleteHandler}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.min.js">
                    {
                        // intentional downgrade, see https://github.com/react-pdf-viewer/react-pdf-viewer/issues/1604#issuecomment-1987619189
                    }
                    <div>
                        <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                            <Button
                                id="back-to-upload-pdf"
                                onClick={props.onClickBack}
                                style={{ alignItems: "center" }}
                                startIcon={<ArrowBack />}
                            >
                                {"back"}
                            </Button>
                            {/* <Button
                                disabled={props.files.length === 0}
                                onClick={() => { if (props.files.length > 0) { autodetectRegions({ file: props.files[0] }) } }}
                                variant="outlined"
                            >
                                {"Autodetect Regions"}
                            </Button> */}
                            <Typography component="span" sx={{ paddingLeft: "10px" }}>
                                {isAutodetectRegions &&
                                    "Autodetecting regions..."
                                }
                            </Typography>
                            <Typography component="span" sx={{ paddingRight: "10px", marginLeft: "auto" }}>
                                {isGeneratingTablesFromPdf &&
                                    "Generating tables from PDF..."
                                }
                            </Typography>
                            <ToggleButtonGroup style={{ height: "40px" }}>
                                <ToggleButton value="selectedRegions" disabled>
                                    {`${currentBoxes.length} regions selected`}
                                </ToggleButton>
                                <ToggleButton value="clear" onClick={() => setCurrentBoxes([])} disabled={currentBoxes.length === 0}>
                                    {"clear"}
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <Button
                                id="send-pdf-to-excel"
                                disabled={currentBoxes.length === 0}
                                onClick={() => setIsOpenConfirmDialog(true)}
                                style={{ alignItems: "center" }}
                                endIcon={<ArrowForward />}
                            >
                                {"process"}
                            </Button>
                        </div>
                        <div
                            style={{
                                border: '1px solid rgba(0, 0, 0, 0.3)',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                        >
                            <div
                                style={{
                                    position: "relative",
                                    top: "0",
                                    left: "0"
                                }}
                                id="selector-pages-container-offset-parent"
                            >
                                <div
                                    style={{
                                        height: '800px',
                                    }}
                                    id="selector-pages-container"
                                >
                                    {
                                        props.fileBytes === undefined ? "Loading PDF..." :
                                            <Viewer
                                                fileUrl={props.fileBytes}
                                                renderPage={renderPage}
                                                defaultScale={1}
                                                plugins={[customZoomPluginInstance]}
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Worker>
                <div>
                    {document.getElementById("selector-pages-container") &&
                        currentBoxes.map((b) => {
                            return (
                                createPortal(
                                    <BoxSelectionWrapper
                                        bc={b.bc}
                                        selected={false}
                                        boxId={"selector-highlight-box-" + b.id}
                                        boxDeleteHandler={boxDeleteHandler}
                                    />,
                                    document.getElementById("selector-pages-container")?.firstChild?.firstChild?.firstChild?.firstChild as Element
                                )
                            );
                        })

                    }
                    {currentBox && document.getElementById("selector-pages-container") &&
                        createPortal(
                            <BoxSelectionWrapper
                                bc={currentBox.getCoords(position.x, position.y)}
                                selected={true}
                                boxId={"selector-highlight-box" + currentBox?.id + "-tmp"}
                                boxDeleteHandler={(e: MouseEvent) => { }}
                            />,
                            document.getElementById("selector-pages-container")?.firstChild?.firstChild?.firstChild?.firstChild as Element
                        )
                    }
                </div>
            </SelectorDeleteContext.Provider>
        </Box >
    )
}

export default PDFViewr;