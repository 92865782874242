import { Box, Button, Paper, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import microsoft from "../assets/img/microsoft.png"
import icon1 from "../assets/img/icon1.png"

const LoginPage = () => {

    return (
        <Box sx={{ height: "100vh", backgroundImage: "linear-gradient(#126537, #3f87a6)" }}>
            <Box sx={{ display: "flex", justifyContent: "center", height: "100%", alignItems: "center" }}>
                {/* <Paper variant="outlined" sx={{ width: 400, height: 250, borderRadius: "5px", boxShadow: "0 1px 11px rgba(0, 0, 0, 0.27)" }}>
                    <Typography sx={{ alignItems: "center", display: "flex", justifyContent: "space-around", marginTop: "20px", fontSize: "22px", color: "darkgreen" }}>
                        Sign in to continue
                    </Typography>
                    <Stack spacing={3} sx={{ alignItems: "center", marginTop: "25px" }}>
                        <Box sx={{ marginTop: "30px", width: "90%" }}>
                            <Button href="http://127.0.0.1:8081/api/oauth2/authorization/ad" fullWidth sx={{ justifyContent: "space-evenly", color: "darkgreen" }} variant={"outlined"}>
                                <img src={microsoft} alt="Microsoft" width={33} style={{ marginLeft: "-16px" }} />
                                Login with Microsoft
                            </Button>
                        </Box>
                        <Box sx={{ width: "90%" }}>
                            <Button href="http://127.0.0.1:8081/api/oauth2/authorization/dt" fullWidth sx={{ justifyContent: "space-evenly", color: "darkgreen" }} variant={"outlined"}>
                                <img src={icon1} alt="Deeptranslate" width={34} />
                                Login with Deeptranslate
                            </Button>
                        </Box>
                    </Stack>
                </Paper> */}
                <Typography sx={{ alignItems: "center", display: "flex", justifyContent: "space-around", marginTop: "20px", fontSize: "25px", color: "white" }}>
                    Please sign in through translation platform to continue
                </Typography>
            </Box>
        </Box>
    )
}

export default LoginPage