import { createStore, Plugin, PluginFunctions, SpecialZoomLevel } from '@react-pdf-viewer/core';
import StoreProps from './StoreProps';
import { useMemo } from "react";



interface CustomZoomPlugin extends Plugin {
    zoomTo(scale: number | SpecialZoomLevel): void;
}

const useCustomZoomPlugin = (): CustomZoomPlugin => {
    const store = useMemo(() => createStore<StoreProps>({}), []);

    return {
        install: (pluginFunctions: PluginFunctions) => {
            store.update('zoom', pluginFunctions.zoom);
        },
        zoomTo: (scale: number | SpecialZoomLevel) => {
            const zoom = store.get('zoom');
            if (zoom) {
                // Zoom to that scale
                zoom(scale);
            }
        },
    };
};

export default useCustomZoomPlugin;