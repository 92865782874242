import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, LinearProgress, LinearProgressProps, MenuItem, Select, SelectChangeEvent, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import QualityCheckFileUploadPanel from "../components/QualityCheckFileUploadPanel";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { loadPairList } from "shared/glossaryutils";
import qualityCheckApi from "store/apis/qualityCheckApi";
import axios, { AxiosError } from "axios";
import { mainFunctionEnumSettings } from "../components/SideMenu";
import { IMessage } from "@stomp/stompjs";
import { fileDownload } from "shared/utils";
import LoadingButton from '@mui/lab/LoadingButton';
import { qualityCheckMyGlossary } from "types/models/QualityCheckMyGlossary";
import { QualityCheckJobStatusUpdateMessage } from "types/models/JobStatusUpdateMessage";
import { JobStatus } from "../types/models/JobStatus";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { registerConnectionCloseCallback, stompClient } from "shared/StompClient";
import StatisticDialog from "components/QualitCheck/StatisticDialog";

type createJobPanelProps = {
    title: string;
}

const statusProgressMapping: { [key: string]: number } = {
    "PENDING": 0,
    "IN_PROGRESS": 20,
    "RUNNING_RETRIEVE_DOC": 40,
    "COMPARE_DOC": 60,
    "RUNNING_ANNOTATE_DOC": 80,
    "COMPLETE": 100,
}

const CreateJobPanel = (props: createJobPanelProps) => {

    const { title } = props;

    const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

    const { refetch: updateCredit, data: user } = qualityCheckApi.useQualityCheckJobUserProfileQuery();
    const { data, refetch } = qualityCheckApi.useQualityCheckMyGlossaryListQuery();
    const [createJob] = qualityCheckApi.useQualityCheckCreateJobMutation();
    const [createJobBilingual] = qualityCheckApi.useQualityCheckCreateJobBilingualMutation();
    // const [initialChecking, { data: wordCount, isSuccess: isInitialCheckingSuccess, isLoading: isInitialChecking }] = qualityCheckApi.useQualityCheckInitialCheckingMutation();

    const [inputFileType, setInputFileType] = useState("pair");
    const [isSaveToMyGlossary, setIsSaveToMyGlossary] = useState(false);
    const [jobId, setJobId] = useState(-1);
    const [isReadyToDownload, setIsReadyToDownload] = useState(false);
    const [creditUsage, setCreditUsage] = useState<number>(-1);
    const [isValidGlossary, setIsValidGlossary] = useState(true);
    const [isValidForm, setIsValidForm] = useState(false);
    const [message, setMessage] = useState<QualityCheckJobStatusUpdateMessage>();
    const [status, setStatus] = useState("PENDING...");
    const [progress, setProgress] = useState(0);
    const [connected, setConnected] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [isStatisticCardOpen, setIsStatisticCardOpen] = useState(false);
    const [statistic, setStatistic] = useState({ total: 0, matched: 0, unmatched: 0 });

    const isButtonDisabled = user?.subscriptionStatus === "ACTIVE" ? !isValidForm || !isValidGlossary : !isValidForm || !isValidGlossary;

    const redirectUrl = process.env.REACT_APP_REDIRECT_URL;

    const [args, setArgs] = useState({
        jobInfo: {
            name: "",
            sourceDoc: [] as File[],
            targetDoc: [] as File[],
            bilingualDoc: [] as File[],
        },
        checkingMode: "",
        glossary: {
            doc: [] as File[],
            name: "",
            id: -1,
        }
    });

    // stompClient.onWebSocketClose = (evt) => {
    //     if (jobId !== -1 && connected && status !== "COMPLETE" && status !== "ERROR") {
    //         stompClient.deactivate();
    //         stompClient.activate();
    //         stompClient.onConnect = () => {
    //             stompClient.subscribe("/queue/job-result/" + jobId, async (msg: IMessage) => {
    //                 const message: QualityCheckJobStatusUpdateMessage = JSON.parse(msg.body);
    //                 setMessage(message);
    //             });
    //         };
    //     }
    // };

    const validateGlossary = useCallback(() => {
        if (title !== "Glossary Check") return;

        if (!isLoggedIn) {
            setIsValidGlossary(args.glossary.doc.length !== 0);
            return;
        }

        if (args.glossary.doc.length !== 0) {
            if (isSaveToMyGlossary) {
                const isUniqueGlossaryName = args.glossary.name.length !== 0 && data?.find(g => g.glossaryName === args.glossary.name) === undefined;
                setIsValidGlossary(isUniqueGlossaryName);
            } else {
                setIsValidGlossary(true);
            }
        } else if (args.glossary.id !== -1) {
            setIsSaveToMyGlossary(false);
            setIsValidGlossary(true);
        } else {
            setIsValidGlossary(false);
        }
    }, [args.glossary.doc.length, args.glossary.id, args.glossary.name, data, isLoggedIn, isSaveToMyGlossary, title]);

    useEffect(() => {
        validateGlossary();
    }, [validateGlossary]);

    useEffect(() => {
        if (jobId === -1) return;
        const deregisterConnectionCloseCallback = registerConnectionCloseCallback((frame) => {
            if (jobId !== -1 && connected && status !== "COMPLETE" && status !== "ERROR") {
                stompClient.onConnect = () => {
                    stompClient.subscribe("/queue/job-result/" + jobId, async (msg: IMessage) => {
                        const message: QualityCheckJobStatusUpdateMessage = JSON.parse(msg.body);
                        setMessage(message);
                    });
                };
            }

        });

        return () => {
            deregisterConnectionCloseCallback();
        }

    }, [connected, jobId, status]);

    useEffect(() => {
        //set glossary name when glossary file uploaded
        setArgs(prevState => ({
            ...prevState,
            glossary: {
                ...prevState.glossary,
                name: args.glossary.doc.length === 0 ? "" : args.glossary.doc[0].name
            }
        }))
        // reset save to my glossary option state
        if (args.glossary.doc.length === 0) {
            setIsSaveToMyGlossary(false)
        }
    }, [args.glossary.doc])

    // useEffect(() => {
    //     // load credit usage data to display when initial checking is success
    //     if (wordCount !== undefined && isInitialCheckingSuccess) {
    //         setCreditUsage(wordCount.sourceWordCount + wordCount.targetWordCount)
    //     }
    // }, [isInitialCheckingSuccess])

    useEffect(() => {
        //validate form
        if (inputFileType === "pair") {
            if (args.jobInfo.sourceDoc.length === 0 || args.jobInfo.targetDoc.length === 0 || args.jobInfo.name === "") {
                setIsValidForm(false)
            } else {
                setIsValidForm(true)
            }
        } else {
            if (args.jobInfo.bilingualDoc.length === 0 || args.jobInfo.name === "") {
                setIsValidForm(false)
            } else {
                setIsValidForm(true)
            }
        }
    }, [args.jobInfo, inputFileType])

    useEffect(() => {
        // request to calculate credit usage when source and target file is uploaded

        setCreditUsage(-1)
        setJobId(-1)
        setConnected(false);
        updateCredit();
        if (connected) {
            stompClient.deactivate();
        }
        // if ((args.jobInfo.sourceDoc.length > 0 && args.jobInfo.targetDoc.length > 0) || args.jobInfo.bilingualDoc.length > 0) {
        //     initialChecking({ sourceFile: args.jobInfo.sourceDoc[0], targetFile: args.jobInfo.targetDoc[0] })
        //     // setCreditUsage(100);
        // }
    }, [args.jobInfo.sourceDoc, args.jobInfo.targetDoc, args.jobInfo.bilingualDoc, updateCredit])

    useEffect(() => {
        setJobId(-1);
        if (connected) {
            stompClient.deactivate();
        }
        setConnected(false);
        //reset state
        setArgs({
            jobInfo: {
                name: title,
                sourceDoc: [] as File[],
                targetDoc: [] as File[],
                bilingualDoc: [] as File[],
            },
            checkingMode: mainFunctionEnumSettings[title].mode,
            glossary: {
                doc: [] as File[],
                name: "",
                id: -1,
            }
        })
        setIsReadyToDownload(false);
        setIsValidGlossary(title === "Glossary Check" ? false : true)
    }, [title])

    useEffect(() => {
        setArgs((prevState) => ({
            ...prevState,
            glossary: {
                ...prevState.glossary,
                name: args.glossary.doc.length === 0 ? "" : args.glossary.doc[0].name
            }
        }));
    }, [args.glossary.doc, isSaveToMyGlossary]);

    useEffect(() => {
        if (jobId !== -1) {
            stompClient.subscribe("/queue/job-result/" + jobId, async (msg: IMessage) => {
                const message: QualityCheckJobStatusUpdateMessage = JSON.parse(msg.body);
                setMessage(message)
            })
        }

    }, [jobId])

    useEffect(() => {
        if (message === undefined) return;
        setStatus(message.status);
        if (message.status.includes("ERROR")) {
            stompClient.deactivate();
            setConnected(false);
            updateCredit();
            toast.error(message.status)
            return
        }
        setProgress(statusProgressMapping[message.status]);
        if (message.status === JobStatus.COMPLETE) {
            const totalResultNumber = message.totalResultNumber;
            const matchedNumber = message.matchedNumber;
            const mismatchedNumber = message.mismatchedNumber;
            setStatistic({ total: totalResultNumber, matched: matchedNumber, unmatched: mismatchedNumber });
            setIsStatisticCardOpen(true);
            stompClient.deactivate();
            setConnected(false);
            updateCredit();
            setIsReadyToDownload(true);
            toast.success("Quality check job is completed")
        }
    }, [message, updateCredit])


    const createUploadHandler = (type: string) => (file: File[]) => {

        if (type === "source") {
            setArgs(prevState => ({
                ...prevState,
                jobInfo: {
                    ...prevState.jobInfo,
                    sourceDoc: file,
                }
            }))
        }

        if (type === "target") {
            setArgs(prevState => ({
                ...prevState,
                jobInfo: {
                    ...prevState.jobInfo,
                    targetDoc: file,
                }
            }))
        }

        if (type === "bilingual") {
            setArgs(prevState => ({
                ...prevState,
                jobInfo: {
                    ...prevState.jobInfo,
                    bilingualDoc: file,
                }
            }))
        }

        if (type === "glossary") {
            setArgs(prevState => ({
                ...prevState,
                glossary: {
                    ...prevState.glossary,
                    doc: file,
                }
            }))
        }
    };

    const handleDownloadResult = async () => {
        setDownloading(true);
        const fileName = args.jobInfo.name + "_RESULT.zip"
        const resultFile = await axios.get(`quality-check/${jobId}/result/export`, {
            responseType: 'blob',
        })
        fileDownload(resultFile.data, fileName);
        setDownloading(false);
    }

    const handleSubmitQualityCheck = async () => {
        setProgress(0)
        setStatus("PENDING...")
        if (isLoggedIn) {
            // if (user!.subscriptionStatus === "INACTIVE" && user!.creditBalance < creditUsage) {
            //     toast.error("Insufficient credit balance, please top-up or subscribe Addcuracy+ to continue!");
            //     return;
            // }
        } else {
            console.log(redirectUrl)
            window.location.href = redirectUrl!;
            return;
        }

        const id = toast.loading("Quality check job submit is pending")
        const glossaryList = args.glossary.doc.length === 0 ? undefined : await loadPairList(args.glossary.doc[0])
        const jobInfoJson = JSON.stringify({
            jobName: args.jobInfo.name,
            checkingMode: args.checkingMode,
            glossaryItems: glossaryList,
            isSaveGlossary: isSaveToMyGlossary,
            myGlossaryId: args.glossary.id === -1 ? undefined : args.glossary.id,
            glossaryName: args.glossary.name
        })
        const jobInfoBlob = new Blob([jobInfoJson], { type: "application/json" });
        stompClient.activate();
        setConnected(true);

        try {
            await createJob({
                jobInfo: jobInfoBlob,
                sourceFile: args.jobInfo.sourceDoc[0],
                targetFile: args.jobInfo.targetDoc[0]
            }).unwrap().then((data) => {
                toast.update(id, { render: "Quality check job submit is successed", type: "success", isLoading: false, autoClose: 2000 });
                setJobId(data.jobId);
                updateCredit();
            }).catch((error) => {
                toast.error(error.message);
            });

            setArgs(prevState => ({
                ...prevState,
                sourceDoc: [] as File[],
                targetDoc: [] as File[],
                glossaryDoc: [] as File[],
                jobName: title,
                glossaryName: "",
                glossaryId: -1,
            }));
            setIsSaveToMyGlossary(false);
            setIsReadyToDownload(false);
            refetch();
        } catch (error) {
            toast.update(id, { render: "update information fail", type: "error", isLoading: false, autoClose: 2000 });
        }
    };

    const handleSubmitQualityCheckBilingual = async () => {
        setProgress(0)
        setStatus("PENDING...")
        if (isLoggedIn) {
            // if (user!.subscriptionStatus === "INACTIVE" && user!.creditBalance < creditUsage) {
            //     toast.error("Insufficient credit balance, please top-up or subscribe Addcuracy+ to continue!");
            //     return;
            // }
        } else {
            window.location.href = redirectUrl!;
            return;
        }

        const id = toast.loading("Quality check job submit is pending")
        const glossaryList = args.glossary.doc.length === 0 ? undefined : await loadPairList(args.glossary.doc[0])
        const jobInfoJson = JSON.stringify({
            jobName: args.jobInfo.name,
            checkingMode: args.checkingMode,
            glossaryItems: glossaryList,
            isSaveGlossary: isSaveToMyGlossary,
            myGlossaryId: args.glossary.id === -1 ? undefined : args.glossary.id,
            glossaryName: args.glossary.name
        })
        const jobInfoBlob = new Blob([jobInfoJson], { type: "application/json" });
        stompClient.activate();
        setConnected(true);

        try {
            await createJobBilingual({
                jobInfo: jobInfoBlob,
                bilingualFile: args.jobInfo.bilingualDoc[0]
            }).unwrap().then((data) => {
                toast.update(id, { render: "Quality check job submit is successed", type: "success", isLoading: false, autoClose: 2000 });
                setJobId(data.jobId);
                updateCredit();
            }).catch((error) => {
                toast.update(id, { render: error.message, type: "error", isLoading: false, autoClose: 2000 });
            });

            setArgs(prevState => ({
                ...prevState,
                sourceDoc: [] as File[],
                targetDoc: [] as File[],
                glossaryDoc: [] as File[],
                jobName: title,
                glossaryName: "",
                glossaryId: -1,
            }));
            setIsSaveToMyGlossary(false);
            setIsReadyToDownload(false);
            refetch();
        } catch (error) {
            toast.update(id, { render: "update information fail", type: "error", isLoading: false, autoClose: 2000 });
        }
    };

    const renderGlossaryHandler = () => {
        return (
            <>
                <Grid item xs={12}>
                    <QualityCheckFileUploadPanel
                        title="Upload glossary file"
                        document={args.glossary.doc}
                        onDrop={createUploadHandler('glossary')}
                        disableGlossary={args.glossary.id !== -1}
                        supportDocTypeOverride={[".xlsx"]}
                    />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "0px" }}>
                    {isLoggedIn &&
                        <FormControlLabel
                            control={<Checkbox disabled={args.glossary.doc.length === 0} sx={{ "&.Mui-checked": { color: "black" } }} />}
                            label="Save to my glossary"
                            checked={isSaveToMyGlossary}
                            onChange={() => { setIsSaveToMyGlossary(!isSaveToMyGlossary) }}
                        />
                    }
                    {isSaveToMyGlossary &&
                        <TextField
                            sx={{
                                borderColor: "black",
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "black"
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'black'
                                    }
                                },
                            }}
                            label={
                                <span color="black">
                                    {"Glossary Name"}
                                </span>
                            }
                            variant="outlined"
                            value={args.glossary.name}
                            onChange={(event) => { setArgs(prevState => ({ ...prevState, glossary: { ...prevState.glossary, name: event.target.value } })) }}
                            error={!isValidGlossary}
                            helperText={args.glossary.name.length === 0 ? "Glossary name cannot be empty" : data?.find(glossary => glossary.glossaryName === args.glossary.name) !== undefined ? "Glossary name already exist" : ""}
                        />
                    }
                </Grid>
                {
                    isLoggedIn &&
                    <Grid container>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} style={{ paddingTop: "0px" }}>
                            <Typography>
                                or
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} style={{ paddingTop: "15px" }}>
                            <Typography variant="subtitle1">
                                Select from:
                            </Typography>
                            <FormControl sx={{ m: 1, minWidth: 200 }}>
                                <InputLabel>My Glossary</InputLabel>
                                <Select
                                    onChange={(event: SelectChangeEvent<typeof args.glossary.id>) => { setArgs((prevState) => ({ ...prevState, glossary: { ...prevState.glossary, id: Number(event.target.value) } })); }}
                                    autoWidth
                                    label="My Glossary"
                                    variant="outlined"
                                    disabled={args.glossary.doc.length !== 0}
                                    value={args.glossary.id}
                                    MenuProps={{
                                        anchorOrigin: { vertical: "bottom", horizontal: "center" },
                                        PaperProps: {
                                            style: {
                                                maxHeight: 48 * 4.5 + 8,
                                            }
                                        }
                                    }}
                                >
                                    <MenuItem value={-1}>
                                        None
                                    </MenuItem>
                                    {data?.map((glossary: qualityCheckMyGlossary) => (
                                        <MenuItem value={glossary.myGlossaryId}>
                                            {glossary.glossaryName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                }
            </>
        )
    }

    const LinearProgressWithLable = (props: LinearProgressProps & { value: number }) => {

        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }

    const renderUploadPanel = (title: string, document: File[], handler: (file: File[]) => void) => (
        <Grid item xs={12} md={inputFileType === "pair" ? 6 : 12}>
            <QualityCheckFileUploadPanel
                title={title}
                document={document}
                onDrop={handler}
            />
        </Grid>
    );

    return (
        <>
            {props.title === "Call Figures" &&
                <StatisticDialog
                    isOpen={isStatisticCardOpen}
                    onClose={() => setIsStatisticCardOpen(false)}
                    statistic={statistic}
                    handleDownloadResult={handleDownloadResult}
                    downloading={downloading}
                />}
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                        {title}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        {mainFunctionEnumSettings[title].functionality}
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <ToggleButtonGroup
                            value={inputFileType}
                            exclusive
                            onChange={() => { setInputFileType(inputFileType === "pair" ? "bilingual" : "pair") }}
                            aria-label="input file type"
                            sx={{ height: "100%", display: "flex" }}
                            size="small"
                        >
                            <ToggleButton value="pair" aria-label="pair">
                                Pair
                            </ToggleButton>
                            <ToggleButton value="bilingual" aria-label="bilingual">
                                Bilingual
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Grid>
                {inputFileType === "bilingual" &&
                    renderUploadPanel("Upload bilingual file", args.jobInfo.bilingualDoc, createUploadHandler('bilingual'))
                }
                {inputFileType === "pair" &&
                    renderUploadPanel("Upload source file", args.jobInfo.sourceDoc, createUploadHandler('source'))
                }
                {inputFileType === "pair" &&
                    renderUploadPanel("Upload target file", args.jobInfo.targetDoc, createUploadHandler('target'))
                }
                {title === "Glossary Check" && renderGlossaryHandler()}
                {
                    isLoggedIn &&
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            label={
                                <span color="black">
                                    Job Name
                                </span>
                            }
                            size="medium"
                            variant="outlined"
                            value={args.jobInfo.name}
                            onChange={(event) => { setArgs(prevState => ({ ...prevState, jobInfo: { ...prevState.jobInfo, name: event.target.value } })) }}
                            sx={{
                                borderColor: "black",
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "black"
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'black'
                                    }
                                },

                            }}
                        />
                    </Grid>
                }
                {/* <Grid item xs={12}>
                    <Typography>
                        Usage: {creditUsage !== -1 && creditUsage}
                    </Typography>
                </Grid> */}
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
                        <LoadingButton
                            sx={{ color: "black" }}
                            onClick={inputFileType === "pair" ? handleSubmitQualityCheck : handleSubmitQualityCheckBilingual}
                            disabled={isButtonDisabled}
                            loading={connected}
                        >
                            {"Start"}
                        </LoadingButton>
                        {isReadyToDownload &&
                            <LoadingButton
                                onClick={handleDownloadResult}
                                loading={downloading}
                            >
                                {"Download"}
                            </LoadingButton>
                        }
                    </Box>
                    {jobId !== -1 &&
                        <Box sx={{ width: '100%' }}>
                            <Typography color={"gray"}>{status}</Typography>
                            <LinearProgressWithLable value={progress} />
                            <Typography>
                                {"* You can check the status and download the results from 'My Document'"}
                            </Typography>
                        </Box>
                    }
                </Grid>
            </Grid >
        </>

    )
}

export default CreateJobPanel;