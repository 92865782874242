import styles from "./tablePreview.module.css";
import '@react-pdf-viewer/core/lib/styles/index.css';

interface TablePreviewData {
    table: string[][],
    formatClasses: number[][],
    firstNumericColumnIndex: number
};

const TablePreview = (props: TablePreviewData) => {
    return (
        <table className={styles['embedded-table-table']} style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}>
            {
                props.table.map((e, i) => {
                    const fcL = props.formatClasses[i];
                    return (
                        <tr>
                            {
                                e.map((e2, j) => {
                                    const fcL2 = fcL[j];
                                    const alignmentStyle: React.CSSProperties = j <= props.firstNumericColumnIndex - 1 ? { textAlign: "left" } : { textAlign: "right" };
                                    return (
                                        <td
                                            className={styles['excel-preview-format-' + fcL2] + " " + styles['embedded-table']}
                                            style={alignmentStyle}
                                        >
                                            {e2}
                                        </td>);
                                })
                            }
                        </tr>
                    );
                })
            }
        </table>
    );
};

export default TablePreview;
