import qualityCheckApi from "../store/apis/qualityCheckApi"
import { useState, useMemo, createContext, useCallback, useRef } from "react";
import MaterialTable, { Column, Components, MTableToolbar } from "@material-table/core";
import { QualityCheckJobItem } from "../types";
import { Box, IconButton, createTheme, ThemeProvider, Typography, Divider, Backdrop, CircularProgress } from "@mui/material";
import { useConfirmDialog } from "../components/ConfirmDialog/ConfirmDialogProvider";
import { useLoadingBackdrop } from "../components/UI/LoadingBackdrop";
import { Add, Delete, Edit, Download } from "@mui/icons-material";
import { toast } from "react-toastify";
import { fileDownload, noop } from "../shared/utils";
import { RefreshTool } from "../components/RefreshTool"
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { withResponsiveHeight } from "components/UI/ResponsiveHeight";
import { Navigate, useNavigate } from "react-router-dom";

export type RowData = Required<QualityCheckJobItem>;

type TableContextValue = {
  toolbar: {
    refreshTool: {
      refreshInterval: number;
      onRefresh: () => void;
      lastUpdateTime?: Date;
    }
  }
}

const TableContext = createContext<TableContextValue>({
  toolbar: {
    refreshTool: {
      refreshInterval: 0,
      onRefresh: noop,
    },
  },
})

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        footer: {
          borderBottom: "none",
        },
      }
    }
  },
});

type DialogType =
  | "quality-check-job-rename-form"

type ActionOnClickHandler<T> = (event: any, data: T | T[]) => void;

type ActionHandlerType =
  | "handleDownloadOnClick"
  | "handleRenameOnClick"
  | "handleDeleteOnClick"

const QualityCheckComponent = () => {

  const { openConfirmDialog } = useConfirmDialog();
  const { withLoadingBackdrop } = useLoadingBackdrop();

  const [openedDialog, setOpenedDialog] = useState<DialogType>();
  const [selectedJobId, setSelectedJobId] = useState(-1);

  const { isLoading, isFetching, data, startedTimeStamp, refetch } = qualityCheckApi.useQualityCheckJobListQuery();
  const [handleOnClickDelete] = qualityCheckApi.useQualityCheckJobDeleteMutation();
  const [handleOnClickRename] = qualityCheckApi.useQualityCheckJobRenameMutation();
  const [isDownloading, setIsDownloading] = useState(false);

  const navigate = useNavigate();

  const qc = useMemo(() => {
    if (data) {
      return data.find(item => item.jobId === selectedJobId);
    }
  }, [selectedJobId, data])

  const handleJobRenameFormSubmit = async (formData: any) => {
    await handleOnClickRename({
      jobId: selectedJobId,
      name: formData.jobName,
    }).unwrap();
    toast.success("Rename quality check job success", { position: toast.POSITION.TOP_RIGHT });
    setOpenedDialog(undefined);

  }

  const lastRefreshDatetime = useMemo(() => {
    if (startedTimeStamp) {
      return new Date(startedTimeStamp);
    }
  }, [startedTimeStamp]);

  const useStablizedCallback = <T extends Function>(callback: T) => {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;
    //@ts-ignore
    return useCallback<T>((...args) => {
      return callbackRef.current.apply(null, args);
    }, []);
  }

  const handleOnRefresh = useStablizedCallback(() => {
    refetch();
  });

  const actionHandlersRef = useRef<Record<ActionHandlerType, ActionOnClickHandler<RowData>>>({
    handleDownloadOnClick: () => { },
    handleRenameOnClick: () => { },
    handleDeleteOnClick: () => { },
  });

  const asItem = <T = any>(value: T | T[]): T => {
    return value as T;
  }

  actionHandlersRef.current = {
    handleDownloadOnClick: async (event, data) => {
      setIsDownloading(true);
      const jobId = asItem(data).jobId
      const fileName = asItem(data).jobName + "_RESULT.zip"
      const resultFile = await axios.get(`quality-check/${jobId}/result/export`, {
        responseType: 'blob',
      });

      await fileDownload(resultFile.data, fileName)
      setIsDownloading(false);
    },
    handleRenameOnClick: async (event, data) => {
      setSelectedJobId(asItem(data).jobId);
      setOpenedDialog("quality-check-job-rename-form")
    },
    handleDeleteOnClick: async (event, data) => {
      openConfirmDialog({
        type: "warning",
        title: "Confirm Delete",
        content: "Are you sure to delete this quality check job?",
        onConfirm: async () => {
          await withLoadingBackdrop(async () => {
            await handleOnClickDelete({
              jobId: asItem(data).jobId,
            }).unwrap();
            toast.success("Delete successfully", { position: toast.POSITION.TOP_RIGHT });
          })
        }
      })
    }
  }

  const mColumns = useMemo<Column<RowData>[]>(() => ([
    {
      title: "ID",
      field: "jobId",
      type: "numeric",
      editable: "never",
      hidden: true,
    },
    {
      title: "Status",
      field: "status",
      editable: "never",
      width: 20,
    },
    {
      title: "Job Name",
      field: "jobName",
      hidenFilterIcon: true,
    },
    {
      title: "Checking Mode",
      field: "checkingMode",
      editable: "never",
    },
    {
      title: "Description",
      field: "description",
      editable: "never",
      render: (data) => data.inputFileType === "PAIR" ? data.sourceFileName + " / \n" + data.targetFileName : data.bilingualFileName
    },
    {
      title: "Usage",
      field: "usage",
      editable: "never",
      render: (data) => data.status === "COMPLETE" ? data.inputFileType === "PAIR" ? data.sourceWordCount : data.sourceWordCount + data.targetWordCount : 0
    },
    {
      title: "Create Date",
      field: "createDate",
      editable: "never",
      type: "datetime"
    },
    {
      title: "Update Date",
      field: "updateDate",
      editable: "never",
      type: "datetime"
    },
  ]), []);


  const mComponents = useMemo<Components>(() => ({
    Container: (props) => <div {...props} />,
    Toolbar: (props) => (
      <TableContext.Consumer>
        {context => (
          <Box width="100%" display="flex" alignItems="center">
            <Box flex="1 0 120px">
              <MTableToolbar {...props} />
            </Box>

            <Box display="flex" alignItems="center" justifyContent="flex-end" flexWrap="wrap">
              <Box mx={2}>
                <RefreshTool tableId="document-table" {...context.toolbar.refreshTool} />
              </Box>

              <Box display="flex" justifyContent="center" alignItems="center" mx={1}>
                <IconButton
                  title={"Create New Quality Check Job"}
                  onClick={() => { navigate("/") }}
                  size="medium"
                >
                  <Add />
                </IconButton>
              </Box>

            </Box>
          </Box>
        )}
      </TableContext.Consumer>
    )
  }), []);

  const mActions = useMemo<MaterialTable<RowData>["props"]["actions"]>(() => ([
    (data) => ({
      icon: Download,
      tooltip: "Download",
      onClick: (event, data) => {
        actionHandlersRef.current.handleDownloadOnClick(event, data);
      },
      disabled: data.status !== "COMPLETE",
    }),
    (data) => ({
      icon: Edit,
      tooltip: "Rename",
      onClick: (event, data) => {
        actionHandlersRef.current.handleRenameOnClick(event, data);
      },
    }),
    (data) => ({
      icon: Delete,
      tooltip: "Delete",
      onClick: (event, data) => {
        actionHandlersRef.current.handleDeleteOnClick(event, data);
      },
    }),
  ]), []);

  const tableContextValue = useMemo<TableContextValue>(() => ({
    toolbar: {
      refreshTool: {
        refreshInterval: 10000,
        onRefresh: handleOnRefresh,
        lastUpdateTime: lastRefreshDatetime,
      }
    }
  }), [handleOnRefresh, lastRefreshDatetime])

  return (
    <TableContext.Provider value={tableContextValue}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme: { zIndex: { drawer: number; }; }) => theme.zIndex.drawer + 1 }}
        open={isDownloading}
      >
        <CircularProgress />
      </Backdrop>
      {!isLoading &&
        <Box>
          <Box sx={{ height: "25px", display: "flex", alignItems: "end" }}>
            <Typography fontSize={25}>
              My Document
            </Typography>
          </Box>
          <Divider />
          <Box>
            <ThemeProvider theme={theme}>
              <MaterialTable
                columns={mColumns}
                data={data ?? []}
                components={mComponents}
                options={{ search: false, toolbar: true, toolbarButtonAlignment: "right", actionsColumnIndex: -1 }}
                isLoading={isFetching}
                actions={mActions}
                style={{ borderBottom: "none" }}
                title={"Job Record"}
              />
            </ThemeProvider>
          </Box>
        </Box>
      }
    </TableContext.Provider>
  )
}

export const QualityCheck = withResponsiveHeight(QualityCheckComponent);
{/* export default QualityCheckComponent */ }