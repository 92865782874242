import { createStyles, Grid, Icon, IconButton, Theme, Typography } from "@mui/material";
import Dropzone from 'react-dropzone';
import { CloudUpload } from '@mui/icons-material';
import withStyles from "@mui/styles/withStyles";
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import AttachFileIcon from '@mui/icons-material/AttachFile';

interface QualityCheckFileUploadPanelProps {
    title: string;
    document: File[];
    onDrop: (file: File[]) => void;
    classes: Record<string, string>;
    disableGlossary?: boolean;
    supportDocTypeOverride?: string[];
}

const supportDocType = ['.docx', '.pptx', '.pdf'];

const styles = (theme: Theme) => createStyles({
    paper: {
        margin: '0 1.5rem 0 1.5rem',
    },
    button: {
        margin: '.8rem 1rem .8rem 0',
    },
    dropzone: {
        border: '2px dashed rgb(0,0,0)',
        padding: '1.2rem',
        margin: '1rem 0 1rem 0',
        background: '#F0F0F0',
        borderRadius: '10px',
        height: "200px"
    },
    dropzoneContent: {
        marginTop: '20px',
        textAlign: 'center',
    },
    dropzoneTypography: {
        color: 'black',
    },
    dropzoneIcon: {
        marginTop: '15px',
        color: 'black',
    },
});

const QualityCheckFileUploadPanel = (props: QualityCheckFileUploadPanelProps) => {

    const { classes } = props;

    const supportDocTypeActive = (props.supportDocTypeOverride === undefined ? supportDocType : props.supportDocTypeOverride);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} style={{ paddingTop: "10px" }}>
                <Typography variant="h6" id="modal-title">
                    {props.title}
                </Typography>
                <div className={classes.dropzone}>
                    <Dropzone
                        onDrop={props.onDrop}
                        onFileDialogCancel={() => { }}
                        accept={supportDocTypeActive.length > 1 ? supportDocTypeActive.join(',') : supportDocTypeActive[0]}
                        disabled={props.disableGlossary}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className={classes.dropzoneContent}>
                                <input {...getInputProps()} />
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    align="center"
                                    className={classes.dropzoneTypography}
                                >
                                    Drop a document here
                                </Typography>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    align="center"
                                    className={classes.dropzoneTypography}
                                >
                                    {
                                        supportDocTypeActive.length > 1 ?
                                            `Upload a ${supportDocTypeActive
                                                .slice(0, -1)
                                                .join(', ')} or ${supportDocTypeActive.slice(-1)}`
                                            :
                                            `Upload a ${supportDocTypeActive[0]}`
                                    }
                                </Typography>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    align="center"
                                    className={classes.dropzoneTypography}
                                >
                                    or click HERE to select a file
                                </Typography>
                                <div className={classes.dropzoneIcon}>
                                    <Icon>
                                        <CloudUpload />
                                    </Icon>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </div>
            </Grid>
            {props.document.length !== 0 &&
                <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                    <AttachFileIcon />
                    <Typography variant="subtitle1">
                        {props.document[0] ? props.document[0].name : ""}
                    </Typography>
                    <IconButton onClick={() => { props.onDrop([] as File[]) }}>
                        <ClearIcon />
                    </IconButton>
                </Grid>
            }
        </Grid>
    )
}

QualityCheckFileUploadPanel.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(QualityCheckFileUploadPanel);