import { createContext, useContext } from "react";
import React from "react";
import styles from "./selectorBox.module.css";

const SelectorDeleteContext = createContext((e: MouseEvent) => {});

interface BoxCoords{
	top: string,
	left: string,
	height: string,
	width: string
};

const BoxSelectionWrapper: React.FC<{bc: BoxCoords,
		selected: boolean, 
		boxId: string,
		boxDeleteHandler: (e: MouseEvent) => void
	}> = ({bc, selected, boxId, boxDeleteHandler}) => {
	return(
		<div style={{
			top: bc.top,
			left: bc.left,
			height: bc.height,
			width: bc.width
		}} className={
			selected ? styles['selector-highlight-box-active'] : styles['selector-highlight-box']
		} id = {boxId}>
			{!selected && 
				<button className={styles['selector-box-button']} id={boxId + "-button"} 
					onClick={(e) => {boxDeleteHandler(e.nativeEvent)}}>
				x
				</button>
			}
		</div>
	);
};

interface BoxSelectionObj{
	bc: BoxCoords,
	percentTop: number,
	percentLeft: number,
	percentBottom: number,
	percentRight: number,
	id: number,
	targetId: number
};

class BoxSelection{
    drawX: number
    drawY: number
    offsetX: number
    offsetY: number
    id: number
    targetId: number
    endX: number
    endY: number
    updated: boolean
    fixed: boolean
    underlyingWidth: number
    underlyingHeight: number
	constructor(offsetx: number, offsety: number, offsetleft: number, offsettop: number, targetid: number, underlyingwidth: number, underlyingheight: number,
        currentBoxId: number
    ){
		this.drawX = offsetx + offsetleft;
		this.drawY = offsety + offsettop;
		this.offsetX = offsetx;
		this.offsetY = offsety;
		this.id = currentBoxId;
		this.targetId = targetid;
		this.endX = this.drawX;
		this.endY = this.drawY;
		this.updated = false;
		this.fixed = false;
		this.underlyingWidth = underlyingwidth;
		this.underlyingHeight = underlyingheight;
	}
	getCoords(offsetX: number, offsetY: number): BoxCoords{
		let lcldrawY = this.drawY;
		let lclendY = this.drawY + (offsetY - this.offsetY);
		if(lclendY < lcldrawY){
			let temp = lclendY;
			lclendY = lcldrawY;
			lcldrawY = temp;
		}
		let lcldrawX = this.drawX;
		let lclendX = this.drawX + (offsetX - this.offsetX);
		if(lclendX < lcldrawX){
			let temp = lclendX;
			lclendX = lcldrawX;
			lcldrawX = temp;
		}
		return {top: lcldrawY + "px", left: lcldrawX + "px", height: (lclendY - lcldrawY) + "px", width: (lclendX - lcldrawX) + "px"};
	}
	getCoordsFromEnd(): BoxCoords{
		return this.getCoords(this.endX + this.offsetX - this.drawX, this.endY + this.offsetY - this.drawY);
	}
	update(offsetX: number, offsetY: number){
		if(!this.updated){
			this.updated = true;
			this.endX = this.drawX + (offsetX - this.offsetX);
			this.endY = this.drawY + (offsetY - this.offsetY);
		}
	}
}

export {BoxSelection, BoxSelectionWrapper, SelectorDeleteContext};
export type {BoxSelectionObj};
