import React from 'react';
import { Dialog, Card, CardContent, Typography, Grid, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';

interface StatisticDialogProps {
    isOpen: boolean;
    onClose: () => void;
    statistic: {
        total: number;
        matched: number;
        unmatched: number;
    };
    handleDownloadResult: () => void;
    downloading: boolean;
}

const StatisticDialog: React.FC<StatisticDialogProps> = ({ isOpen, onClose, statistic, handleDownloadResult, downloading }) => {
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <Card sx={{ minWidth: 275, padding: 0, borderRadius: "10px" }}>
                <CardContent sx={{ paddingBottom: '16px !important' }}>
                    <Typography variant="h6" gutterBottom sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        {"Result statistic"}
                        <AssessmentOutlinedIcon />
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                                <Typography variant="body1" gutterBottom>
                                    {"Number of figures looked at by Call Figure:"}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {statistic.total}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                                <Typography variant="body1" gutterBottom>
                                    {"Of these, the number of figures that were MATCHED (green highlight)"}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {statistic.matched}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                                <Typography variant="body1" gutterBottom>
                                    {"Remaining UNMATCHED (grey comments), which need to be checked by user"}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {statistic.unmatched}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>{"Work saved by using CallFigure"}</strong>
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <strong>{`${Math.round(statistic.matched / statistic.total * 100)}%`}</strong>
                                </Typography>
                            </Box>
                        </Grid>
                        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                            <LoadingButton onClick={handleDownloadResult} loading={downloading}>
                                {"Download"}
                            </LoadingButton>
                        </Box>
                    </Grid>
                    <Typography gutterBottom textAlign="center">
                        {"Thank you for using Addcuracy :)"}
                    </Typography>
                </CardContent>
            </Card>
        </Dialog>
    );
};

export default StatisticDialog;