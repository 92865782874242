import { Middleware, MiddlewareAPI, isRejectedWithValue } from "@reduxjs/toolkit"

export const unauthorizedMiddleware: Middleware =


    (api: MiddlewareAPI) => (next) => (action) => {
        const dtRedirectUrl = process.env.REACT_APP_DT_REDIRECT_URL;
        const stRedirectUrl = process.env.REACT_APP_ST_REDIRECT_URL;
        
        const userPlatformInfo = api.getState().auth.user?.linkedProvider;
        const userPlatformDt = userPlatformInfo?.dt;
        const userPlatformSt = userPlatformInfo?.st;

        if (isRejectedWithValue(action)) {
            const errorStatus = action.payload.status;
            // if (errorStatus === 401 && api.getState().auth.isLoggedIn) {
            //     window.location.href = redirectUrl!;
            // }

            if (errorStatus === 401) {
                if (userPlatformDt !== undefined) {
                    window.location.href = dtRedirectUrl!;
                    return;
                }
                if (userPlatformSt !== undefined) {
                    window.location.href = stRedirectUrl!;
                    return;
                }
                if (userPlatformDt === undefined && userPlatformSt === undefined) {
                    window.location.href = "/login";
                    return;
                }
            }
        }

        return next(action);
    }
