import { Navigate, Outlet } from "react-router-dom"

type GuardedRouteProps = {
    isLoggedIn: boolean;
}

const GuardedRoute = (props: GuardedRouteProps) => {

    const redirectUrl = process.env.REACT_APP_REDIRECT_URL;

    return (
        props.isLoggedIn ? <Outlet/> : <Navigate to={redirectUrl!} />
    )
}

export default GuardedRoute;