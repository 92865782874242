import { Box, Button, ButtonBase, Checkbox, Dialog, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography, Tooltip } from "@mui/material"
import { useEffect, useState } from "react"
import qualityCheckApi from "store/apis/qualityCheckApi"
import { qualityCheckMyGlossary, qualityCheckMyGlossaryItems } from "types/models/QualityCheckMyGlossary"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import axios from "axios";
import { fileDownload } from "shared/utils";
import { isEqual } from "lodash";
import { toast } from "react-toastify";

type EditGlossaryPanelProps = {
    isOpen: boolean
    glossary?: qualityCheckMyGlossary
    onClickClose: () => void
}

const EditGlossaryPanel = (props: EditGlossaryPanelProps) => {

    const [isEditable, setIsEditable] = useState(false)

    const [myGlossaryItem, setMyGlossaryItem] = useState<qualityCheckMyGlossary>({ myGlossaryId: -1, glossaryName: "", glossaryItems: [] });

    const [modifiedGlossaryList, setModifiedGlossaryList] = useState<number[]>([]);
    const [deletedGlossaryList, setDeletedGlossaryList] = useState<number[]>([]);
    const [selectedGlossary, setSelectedGlossary] = useState<number[]>([]);
    const [columnWidth, setColumnWidth] = useState<{ en: string, zh: string }>({ en: "", zh: "" })

    const [modifyMyGlossary, { isLoading }] = qualityCheckApi.useQualityCheckMyGlossaryGlossaryModifyMutation();

    useEffect(() => {
        if (props.glossary) {
            setMyGlossaryItem(props.glossary)
        }
        if (!props.isOpen) {
            setIsEditable(false)
        }
    }, [props.glossary, props.isOpen])

    useEffect(() => {
        const enList = myGlossaryItem.glossaryItems.map((glossary) => glossary.en);
        const zhList = myGlossaryItem.glossaryItems.map((glossary) => glossary.zh);
        const enLongestLength = enList.reduce((a, b) => a.length > b.length ? a : b, "").length;
        const zhLongestLength = zhList.reduce((a, b) => a.length > b.length ? a : b, "").length;
        const enWidth = `${enLongestLength * 0.875} rem`;
        const zhWidth = `${zhLongestLength * 0.875} rem`;
        setColumnWidth({ en: enWidth, zh: zhWidth })

    }, [myGlossaryItem])

    const handleDelete = () => {
        setMyGlossaryItem({
            ...myGlossaryItem,
            glossaryItems: myGlossaryItem.glossaryItems.filter((glossary, index) => !selectedGlossary.includes(index))
        });
        setDeletedGlossaryList([...deletedGlossaryList, ...myGlossaryItem.glossaryItems.filter((glossary, index) => selectedGlossary.includes(index) && glossary.glossaryId !== -1).map((glossary) => glossary.glossaryId)])
        setSelectedGlossary([])
    }

    const onClickCheckBox = (index: number) => {
        if (selectedGlossary.includes(index)) {
            setSelectedGlossary(selectedGlossary.filter((selected) => selected !== index))
        } else {
            setSelectedGlossary([...selectedGlossary, index])
        }
    }

    const onClosePanel = () => {
        setModifiedGlossaryList([])
        setDeletedGlossaryList([])
        setSelectedGlossary([])
    }

    const handleSaveModification = () => {
        setIsEditable(false)
        if (!isEqual(props.glossary, myGlossaryItem)) {
            const addedGlossary = myGlossaryItem.glossaryItems.filter((glossary) => glossary.glossaryId === -1)
            const deletedGlossary = deletedGlossaryList
            const modifiedGlossary = myGlossaryItem.glossaryItems.filter((glossary, index) => modifiedGlossaryList.includes(index) && glossary.glossaryId !== -1)

            modifyMyGlossary(
                {
                    myGlossaryId: myGlossaryItem.myGlossaryId,
                    editedName: myGlossaryItem.glossaryName,
                    editedGlossary: modifiedGlossary,
                    addedGlossary: addedGlossary,
                    deletedGlossary: deletedGlossary
                }).unwrap().then(() => {
                    const renderToastSentence = (length: number) => {
                        if (length > 1) {
                            return `${length} glossaries`
                        } else {
                            return `${length} glossary`
                        }
                    }
                    if (addedGlossary.length > 0) {
                        toast.success(`Added ${renderToastSentence(addedGlossary.length)} to "${myGlossaryItem.glossaryName}"`)
                    }
                    if (modifiedGlossary.length > 0) {
                        toast.success(`Modified ${renderToastSentence(modifiedGlossary.length)} in "${myGlossaryItem.glossaryName}"`)
                    }
                    if (deletedGlossary.length > 0) {
                        toast.success(`Deleted ${renderToastSentence(deletedGlossary.length)} from "${myGlossaryItem.glossaryName}"`)
                    }
                })
            setModifiedGlossaryList([])
            setDeletedGlossaryList([])
            setIsEditable(false)
            setSelectedGlossary([])
        } else {
            toast.info("No modification")
        }
    }

    const onChangeGlossary = (event: React.ChangeEvent<HTMLInputElement>, glossary: qualityCheckMyGlossaryItems, lang: string, index: number) => {
        setMyGlossaryItem({
            ...myGlossaryItem, glossaryItems: myGlossaryItem.glossaryItems.map((item, idx) => {
                if (idx === index) {
                    if (lang === "en") {
                        return { ...item, en: event.target.value };
                    } else {
                        return { ...item, zh: event.target.value };
                    }
                } else {
                    return item;
                }
            })
        })
        setModifiedGlossaryList([...modifiedGlossaryList, index])
    }

    const handleExportGlossary = async () => {
        const fileName = myGlossaryItem.glossaryName + ".xlsx"
        const resultFile = await axios.post(`quality-check/extract-glossary/export`, JSON.stringify(myGlossaryItem.glossaryItems), {
            responseType: 'blob',
            headers: {
                "Content-Type": "application/json"
            }
        });
        fileDownload(resultFile.data, fileName)
    }

    if (isEqual(myGlossaryItem, { myGlossaryId: -1, glossaryName: "", glossaryItems: [] })) {
        return (<></>)
    }
    else {
        return (
            <Dialog
                fullWidth
                open={props.isOpen}
                onBackdropClick={props.onClickClose}
                PaperProps={{ sx: { borderRadius: "5px", } }}
                onClose={() => onClosePanel()}
            >
                <Toolbar sx={{ minHeight: "45px" }}>
                    <Typography
                        sx={{ flex: '1 1 100%', textAlign: "center" }}
                        variant="h6"
                        component="div"
                    >
                        <TextField
                            fullWidth
                            value={myGlossaryItem.glossaryName}
                            inputProps={{ sx: { textAlign: "center", fontWeight: "500" } }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setMyGlossaryItem({ ...myGlossaryItem, glossaryName: event.target.value }) }}
                            InputProps={{ disableUnderline: isEditable ? false : true }}
                            disabled={!isEditable}
                            sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                },
                            }}
                        />
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                        <Tooltip title="modify glossary">
                            <ButtonBase onClick={() => { setIsEditable(!isEditable) }}>
                                <EditIcon />
                            </ButtonBase>
                        </Tooltip>
                        {!isEditable &&
                            <Tooltip title="export glossary">
                                <ButtonBase onClick={handleExportGlossary} disabled={myGlossaryItem.glossaryName === ""}>
                                    <FileDownloadIcon />
                                </ButtonBase>
                            </Tooltip>
                        }
                        {isEditable &&
                            <Tooltip title="delete">
                                <ButtonBase onClick={handleDelete}>
                                    <DeleteIcon />
                                </ButtonBase>
                            </Tooltip>
                        }
                    </Box>
                </Toolbar>
                <TableContainer
                    sx={{
                        maxHeight: "500px",
                        "::-webkit-scrollbar": { width: "5px", transform: "rotate(180deg)" },
                        "::-webkit-scrollbar-thumb": { background: "black" }
                    }}
                >
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox" />
                                <TableCell sx={{ paddingTop: "0px" }}>{"English"}</TableCell>
                                <TableCell sx={{ paddingTop: "0px" }}>{"Chinese"}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {myGlossaryItem.glossaryItems.map((glossaryItem, index) => (
                                <TableRow sx={{ height: "30px" }}>
                                    <TableCell sx={{ padding: "0px 0px 0px 16px", minWidth: "40px" }}>
                                        {isEditable &&
                                            <Checkbox
                                                checked={selectedGlossary.includes(index)}
                                                onClick={() => onClickCheckBox(index)}
                                                sx={{ padding: "0px" }}
                                            />
                                        }
                                    </TableCell>
                                    <TableCell sx={{ padding: "0px 16px" }}>
                                        <TextField
                                            value={glossaryItem.en}
                                            fullWidth
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeGlossary(event, glossaryItem, "en", index)}
                                            InputProps={{ style: { fontSize: "0.875rem", width: columnWidth.en }, disableUnderline: isEditable ? false : true }}
                                            disabled={!isEditable}
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "black",
                                                },
                                            }}
                                        />

                                    </TableCell>
                                    <TableCell sx={{ padding: "0px 16px" }}>
                                        <TextField
                                            value={glossaryItem.zh}
                                            fullWidth
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeGlossary(event, glossaryItem, "zh", index)}
                                            InputProps={{ style: { fontSize: "0.875rem", width: columnWidth.zh }, disableUnderline: isEditable ? false : true }}
                                            disabled={!isEditable}
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "black",
                                                },
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                            {isEditable && (
                                <TableRow>
                                    <TableCell colSpan={3} sx={{ padding: "0px 16px" }}>
                                        <ButtonBase
                                            sx={{ fontWeight: "700", width: "-webkit-fill-available", height: "30px" }}
                                            onClick={() =>
                                                setMyGlossaryItem({
                                                    ...myGlossaryItem,
                                                    glossaryItems: [...myGlossaryItem.glossaryItems, { glossaryId: -1, en: "", zh: "" }],
                                                })
                                            }
                                        >
                                            + NEW
                                        </ButtonBase>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Divider />
                <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                    <Button onClick={props.onClickClose} fullWidth>
                        {"Close"}
                    </Button>
                    <Divider />
                    <Button
                        onClick={handleSaveModification}
                        fullWidth
                    >
                        {"Save"}
                    </Button>
                </Box>
            </Dialog >
        )
    }
}

export default EditGlossaryPanel