import { Box, Button, Grid, Typography } from "@mui/material";
import QualityCheckFileUploadPanel from "../components/QualityCheckFileUploadPanel";
import { useEffect, useState } from "react";
import { mainFunctionEnumSettings } from "../components/SideMenu";
import checkSumApi from "store/apis/checkSumApi";
import PDFViewr from "components/CheckSum/PDFViewer";
import { PdfToExcelConversion, PdfToExcelConversionObject } from "types/models/PdfToExcelConversion";
import CheckSumResult from "components/CheckSum/CheckSumResult";
import { RootState } from "store/store";
import { useSelector } from "react-redux";

enum FileTypes {
    None,
    Pdf,
    Excel,
    ExtractedTables,
    ExtractedTablesFromExcel,
    Results
};

const CheckSumPage = () => {
    const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

    const [checkSumId, setCheckSumId] = useState(-1);
    const [checkSumResultTables, setCheckSumResultTables] = useState<PdfToExcelConversion | undefined>(undefined);

    const [selectedFile, setSelectedFile] = useState([] as File[]);
    const [fileType, setFileType] = useState(FileTypes.None);
    const [fileBytes, setFileBytes] = useState(undefined as Uint8Array | undefined);
    const [getExcelTable, { data: excelTable }] = checkSumApi.useExcelToTableConversionMutation();

    const [correctableTables, setCorrectableTables] = useState([] as boolean[]);
    const [colSets, setColSets] = useState([] as Set<number>[]);
    const [rowSets, setRowSets] = useState([] as Set<number>[]);

    const redirectUrl = process.env.REACT_APP_REDIRECT_URL;

    const resetFile = () => {
        setSelectedFile([]);
        setFileType(FileTypes.None);
        setFileBytes(undefined);
    }

    const renderPdfViewr = () => {
        if (isLoggedIn) {
            // if (user!.subscriptionStatus === "INACTIVE" && user!.creditBalance < creditUsage) {
            //     toast.error("Insufficient credit balance, please top-up or subscribe Addcuracy+ to continue!");
            //     return;
            // }
        } else {
            window.location.href = redirectUrl!;
            return;
        }
        setFileBytes(undefined);
        if (selectedFile.length > 0) {
            const fileExtension = selectedFile[0].name.split(".").pop();
            if (fileExtension === "pdf") {
                setFileType(FileTypes.Pdf);
                const buf = selectedFile[0].arrayBuffer()
                buf.then((val) => setFileBytes(new Uint8Array(val)));
            } else if (fileExtension === "xls" || fileExtension === "xlsx") {
                setFileType(FileTypes.Excel);
                getExcelTable({ file: selectedFile[0] });
            } else {
                setFileType(FileTypes.None);
            }
        } else {
            setFileType(FileTypes.None);
        }
    };

    const getActiveGeneratedTables = () => fileType === FileTypes.ExtractedTablesFromExcel ? (excelTable ? excelTable.processedTableOutputList : undefined) : checkSumResultTables?.processedTableOutputList;

    const processTablePrerenderLogic = (tableList: PdfToExcelConversionObject[]) => {
        setCorrectableTables(tableList.map((t) => t.sums.some(x => x.hasError)));
        setColSets(tableList.map((t) => {
            const newSet = new Set<number>();
            t.sums.forEach((sumObj) => {
                for (let i = sumObj.column; i < sumObj.column + sumObj.width; i++) {
                    newSet.add(i);
                }
            })
            return newSet;
        }));
        setRowSets(tableList.map((t) => {
            const newSet = new Set<number>();
            t.sums.forEach((sumObj) => {
                for (let i = sumObj.row; i < sumObj.row + sumObj.height; i++) {
                    newSet.add(i);
                }
            })
            return newSet;
        }));
    };

    useEffect(() => {
        if (checkSumResultTables !== undefined) {
            processTablePrerenderLogic(checkSumResultTables.processedTableOutputList);
            setFileType(FileTypes.ExtractedTables);
            setCheckSumId(checkSumResultTables.checkSumId);
        }
    }, [checkSumResultTables]);

    const onTableCorrectionChangeHandler = (index: number, newValue: boolean) => {
        setCorrectableTables((oldCorrectableTables) => {
            const newCorrectableTables = [...oldCorrectableTables];
            newCorrectableTables[index] = newValue;
            return newCorrectableTables;
        });
    };

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                    {"Sum Check"}
                </Typography>
                <Typography variant="h6" gutterBottom>
                    {mainFunctionEnumSettings["Table Sums Check"].functionality}
                </Typography>
            </Grid>
            {fileType !== FileTypes.Pdf && fileType !== FileTypes.ExtractedTables &&
                <>
                    <Grid item xs={12} md={12}>
                        <QualityCheckFileUploadPanel
                            title="Upload text-based .pdf"
                            document={selectedFile}
                            onDrop={(f: File[]) => { setSelectedFile(f) }}
                            supportDocTypeOverride={[".pdf"]}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
                            <Button
                                sx={{ color: "black" }}
                                onClick={renderPdfViewr}
                                disabled={selectedFile.length === 0}
                            >
                                {"Select table regions"}
                            </Button>
                        </Box>
                    </Grid>
                </>
            }
            {
                fileType === FileTypes.Pdf &&
                <Grid item xs={12} >
                    <PDFViewr
                        fileType={fileType}
                        fileBytes={fileBytes}
                        files={selectedFile}
                        setCheckSumResultTables={setCheckSumResultTables}
                        onClickBack={resetFile}
                    />
                </Grid>
            }
            {
                (fileType === FileTypes.ExtractedTables || fileType === FileTypes.ExtractedTablesFromExcel) &&
                <Grid item xs={12} >
                    <CheckSumResult
                        checkSumId={checkSumId}
                        resultTables={getActiveGeneratedTables()}
                        correctableTables={correctableTables}
                        onTableCorrectionChangeHandler={onTableCorrectionChangeHandler}
                        colSets={colSets}
                        rowSets={rowSets}
                        startNewCheckSum={resetFile}
                    />
                </Grid>
            }
        </Grid >
    )
}

export default CheckSumPage;